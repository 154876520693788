html {
  height: 100%;
  width: 100%;
}

body {
  font-family: 'Arial', sans-serif;
  background-color: #171717;
  width: 100%;
  height: 100%;
}

#root{
  width: 100%;
  height: 100%;
  overflow: auto;
}

.App {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.faq-container{
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: auto;
  padding-bottom: 50px;
}

.faq-container-card-body{
  padding-left: 5rem;
  padding-right: 5rem;
  padding-top: 3rem;
}

.home-body{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 85%;
}

.home-body-search{
  width: 100%;
  height: 55%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  align-content: center;
}

.home-body-search-inputcontainer{
  /* display: flex;
  justify-content: center;
  flex-direction: row; */
  width: 100%;
  max-width: 400px; 
  /* height: 60%; */
}

.home-body-recent{
  width: 100%;
  height: 45%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-left: 10%;
  padding-right: 10%;
}

.home-body-recent-title{
  width: 100%;
  height: 20%;
}

.home-body-recent-body{
  width: 100%;
  height: 80%;
  flex-direction: row;
}

.home-body-recent-grid{
  /* width: 80%; */
  /* height: 200px; */
  /* overflow: hidden; */
}

.home-body-recent-grid-card{
  height: 90%;
}

.home-body-recent-grid-card-footer{
  display: flex;
  flex-direction: row;
  /* justify-content: space-evenly; */
}

.home-footer{
  display: flex;
  align-self: flex-start;
  justify-content: center;
  width: 100%;
  height: 10%;
}

.label {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.input {
  width: 100%;
  padding: 0.5rem;
  font-size: 1rem;
  border: 1px solid #cccccc;
  border-radius: 3px;
  margin-bottom: 1rem;
}

.submit-btn {
  background-color: #007bff;
  color: #ffffff;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.submit-btn:hover {
  background-color: #0056b3;
}

.paper-container {
  display: flex;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  /* border-radius: 5px; */
  overflow: hidden;
}

.pdf-viewer {
  flex: 2;
}

.pdf-frame {
  width: 100%;
  height: 100%;
}

.pdf-viewer-right-panel-container {
  flex: 1;
  /* padding: 2rem; */
  overflow-y: auto;
  height: 100%;
}

.pdf-viewer-right-panel-container h2 {
  margin-bottom: 1rem;
}

/* Chat Screen  */
.chat-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
  /* padding: 2rem; */
}

.chat-history {
  height: 85%;
  order: 1;
  overflow: auto;
  display: flex;
  flex-direction: column;
  /* align-items: flex-start;
  justify-content: flex-start; */
  /* width: 100%;
  height: 90%; */
}

.chat-form {
  height: 15%;
  order: 2;
  overflow: hidden;
}

/* Chat Bubble Style */
.chat-bubble {
  display: flex;
  flex-direction: column;
  max-width: 60%;
  margin: 10px;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
  font-size: 16px;
  line-height: 1.5;
}

.human-chat-bubble {
  align-self: flex-end;
  background-color: #2196F3;
  color: white;
}

.machine-chat-bubble {
  align-self: flex-start;
  background-color: #f5f5f5;
  color: black;
}

/* Underline the name in the footer */
.made-with-love a {
  text-decoration: underline;
}

/* Showing social media post in PDF viewer */
.social-media-post-text-view{
  white-space: pre-line;
}

/* GPTs */
/* ---------- */
.gpts-body{
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  width: 100%;
}
.gpts-header{
  height: 10%;
  width: 100%;
  display: flex;
  align-items: end;
  justify-content: center;
}
.gpts-highlights{
  height: 10%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.gpts-card-container {
  height: 80%;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  overflow-y: auto;
}
.gpts-card {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  overflow: hidden;
  width: 200px;
  margin: 10px;
}
.gpts-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0,0,0,0.2);
}
.gpts-card img {
  width: 100%;
  display: block;
}
.gpts-card-content {
  padding: 20px;
  text-align: center;
}
.gpts-card-content h3 {
  margin: 0;
  color: #333;
  font-size: 18px;
}
.gpts-card-content p {
  color: #666;
  font-size: 14px;
}
/* Style for the anchor tag to cover the entire card */
.gpts-card a {
  text-decoration: none;
  color: inherit;
  display: block;
  height: 100%;
}